import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React from "react"


import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => {
    const pageQuery = useStaticQuery(graphql`
    query teamQuery {
        allWpTeamMember(sort: { fields: [date], order: ASC }) {
        nodes {
            title
            uri
            team {
            title
            description
            number
            linkedin
            image {
                id
                sourceUrl
                altText
                localFile {
                    extension
                    childImageSharp {
                        
                        gatsbyImageData(
                            width: 400
                            placeholder: BLURRED
                        )
                    }
                  }
            }
            email
            }
        }
        }
        allWpPage(filter: {uri: {eq: "/about/"}}) {
            nodes {
                uri
                title
                content
                seo {
                    metaDesc
                    title
                }
            }
        }
    } 
    `)
    const team = pageQuery.allWpTeamMember.nodes
    const pageContent = pageQuery.allWpPage.nodes

    return (
        <div>
            <Seo title={pageContent.seo?.title ? pageContent.seo.title  : 'Meet the team' } description={pageContent.seo?.metaDesc} />
            <Layout>
                <section className="header_section">
                <div className="container">
                    <div className="header_section__content">
                        <h1>About</h1>
                        <div className="header_section__content_sub">
                            <div>The Expert Recruiters in Medical Aesthetics </div>
                        </div>
                        <div className="header_section__content_btn text-center">
                            <a href="#content">
                                <svg xmlns="http://www.w3.org/2000/svg" className="bounce" width="109.491" height="57.574" viewBox="0 0 109.491 57.574">
                                    <path id="Path_5" data-name="Path 5" d="M-847.3,2972.583h0l53.331,53.331-53.331,53.332" transform="translate(3080.66 848.711) rotate(90)" fill="none" stroke="rgba(53,51,67,0.5)" strokeWidth="4"/>
                                </svg>
                            </a>
                        </div> 
                    </div>
                    <div className="header_section__image">
                        <div className="header-container--standars">
                                <StaticImage src="../../static/assets/images/header/image_3.svg" placeholder="blurred" alt="blurred image_3" />
                        </div> 
                    </div>
                </div>
                <div className="standard_header_path">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 1924.003 706.339">
                        <path id="Path_1" data-name="Path 1" d="M.5,1210.979h1924V504.64S1271.73,915.921,929.1,801.894.5,1037.8.5,1037.8Z" transform="translate(-0.5 -504.64)" fill="#ecebe4"/>
                    </svg>                      
                </div>
            </section>

            <section className="quote_text">
                <div className="container" id="content">
                    <div className="section__title text-center">About ARC</div>

                    <div className="quote_text_holder">
                        <div
                        dangerouslySetInnerHTML={{
                            __html: pageContent[0].content
                        }}
                        />
                    </div>
                    <div className="quote_text_author">John Sellers, Managing Director</div>
                </div>
            </section>

            <section className="meet_team" id="section_team">
                <div className="container">
                    <div className="section__title text-center">
                        Meet the Team
                    </div>
                    <div className="meet_team_holder">
                        {team.map(staff => {
                            const image = getImage(staff.team.image.localFile)

                            return (
                                <div key={staff.team.number} className="meet_team__member" id={'team' + staff.title.replace(/ /g, '')}>
                                    <div className="meet_team__member_image">
                                        <GatsbyImage image={image} alt={staff.team.image.altText} style={{
                                            borderRadius: '100%', width: '300px', maxWidth: '100%'
                                        }} />
                                    </div>
                                    <div className="meet_team__member_desc">
                                        <div className="meet_team__member_desc_title">{staff.title}</div>
                                        <div className="meet_team__member_desc_title">{staff.team.title}</div>
                                            <div 
                                                dangerouslySetInnerHTML={{
                                                    __html:  staff.team.description
                                                }} />
                                        <div className="meet_team__member_desc_links">
                                            <a href={`mailto:${staff.team.email}`}>{staff.team.email}</a>
                                            <a href={`tel:${staff.team.number}`}>{staff.team.number}</a>
                                            <a href={staff.team.linkedin} className="underline" target="_blank" rel="noreferrer">Linkedin</a>
                                        </div>
                                    </div>
                                </div>
                                )
                            })}
                    </div>
                </div>
                </section>
            </Layout>
        </div>
    )
}

export default AboutPage

